export default function filterBytes(count) {
  const units = ['B', 'KB', 'MB', 'GB'];
  let i = 0;
  while (count > 1024 && i < units.length) {
    count = count / 1024;
    i++;
  }
  count = Math.round(count * 100) / 100;
  return `${count}${units[i]}`;
}
