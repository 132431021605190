import { SHOP_INFO } from '../constants';
import { setItem, getItem, removeItem } from '@/utils/localStorage';

export * from './user';

export function setShopInfo(shop) {
  setItem(SHOP_INFO, shop);
}

export function getShopInfo() {
  return getItem(SHOP_INFO);
}

export function removeShopInfo() {
  removeItem(SHOP_INFO);
}
