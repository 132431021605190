<template>
  <div class="picture-card">
    <Upload
      :limit="limit"
      list-type="picture-card"
      :on-preview="onPreview"
      :on-remove="onRemove"
      :file-list="value"
      :on-success="onSuccess"
      multiple
      :before-upload="onBeforeUpload"
      :on-exceed="onExceed"
      :on-change="onChange"
      :class="{ hiddenBtn: !showBtn }"
      accept="image/jpeg, image/png"
      v-bind="$attrs"
    >
      <i class="el-icon-plus"></i>
    </Upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
  </div>
</template>
<script>
import Upload from './index';
import filterBytes from '@/utils/bytes';
export default {
  name: 'picture-card',
  inheritAttrs: false,
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: []
    };
  },
  computed: {
    showBtn() {
      if (this.disabled) {
        return false;
      }

      if (!this.limit || !this.value) {
        return true;
      }
      return this.limit > this.value.length;
    },
    maxSizeText() {
      return filterBytes(this.maxSize);
    }
  },
  methods: {
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    onPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onRemove(file, fileList) {
      this.fileList = fileList;
      this.$emit('input', fileList);
    },
    onBeforeUpload(file) {
      const fileTypeSet = new Set(['image/jpeg', 'image/png']);

      const testFileType = fileTypeSet.has(file.type);
      const less = file.size < this.maxSize;

      if (!testFileType) {
        this.$notify.error('上传的图片只能是 JPG 或 PNG 格式!');
      }
      if (!less) {
        this.$notify.error(`上传图片大小不能超过 ${this.maxSizeText}!`);
      }
      return testFileType && less;
    },
    onSuccess(res, file, fileList) {
      file.url = res.data;
      this.$emit('input', fileList);
    },
    onExceed(files, fileList) {
      this.$notify.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    }
  },
  components: {
    Upload
  },
  props: {
    value: Array,
    limit: Number,
    disabled: Boolean,
    maxSize: {
      // 最大体积
      type: Number,
      default: 1024 * 1024 * 1 // 默认2M
    }
  }
};
</script>
<style lang="scss" scoped>
.hiddenBtn {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>

